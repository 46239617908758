.result-error {
  margin-top: 40px;
}

.result-error .size {
  text-align: center;
}

.result-error .size .error-mark-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 5px;
  left: 62px;
}

.result-error .size .error-mark {
  width: 85px;
  height: 23px;
  border: 1px solid #C41A1A;
  border-radius: 8px;
  background-color: #fff;
}

.result-error .size .error-mark p {
  font-size: 11px;
  font-weight: 600;
  color: #C41A1A;
  margin-top: 5px;
}

.result-error .size h1 {
  font-size: 124px;
  font-weight: 700;
  color: #C0C0C0;
  margin: 0;
  max-height: 100px;
}

.result-error .size .size-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.result-error .size .size-name h2 {
  font-size: 16px;
  font-weight: 600;
}

.result-error .size .size-name svg {
  font-size: 16px;
}

.result-error .size .sizes-menu {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.result-error .size .sizes-menu .menu-item {
  width: 135px;
  height: 29px;
  border: 1px solid #F0F0F0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.result-error .size .sizes-menu .menu-item p {
  font-size: 13px;
  font-weight: 400;
}

.result-error .size .sizes-menu .active {
  background-color: #002D18;
  border: none;
}

.result-error .size .sizes-menu .active p {
  color: #F0F0F0;
  font-size: 13px;
  font-weight: 600;
}

.result-error-body {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.result-error-body .result-title {
  font-size: 16px;
  font-weight: 500;
  color: #002D18;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.result-error-body .gallery {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.result-error-body .gallery__image-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  justify-content: center;
  gap: 5px;
}

.result-error-body .gallery__image {
  min-width: 100%;
  transition: opacity 0.3s ease-in-out;
  position: relative;
}

.result-error-body .gallery__image img {
  width: 141px;
  height: 202px;
}

.result-error-body .gallery__image--visible {
  opacity: 1;
}

.result-error-body .gallery .gallery__item .gallery__info h3 {
  font-size: 13px;
  font-weight: 600px;
  margin-top: 2px;
  margin-bottom: 0;
}

.result-error-body .gallery .gallery__item .gallery__info p {
  font-size: 11px;
  font-weight: 600px;
  margin-top: 2px;
  margin-bottom: 0;
}

.result-error-body .gallery__arrow {
  background: transparent;
  color: #000;
  border: none;
  cursor: pointer;
}

.step-bottom-error {
  margin-top: 100px;
}

.skip-error {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}

.result-error-body .divider {
  width: 55%;
  margin: 0 auto;
  border-bottom: 1px solid #E1E0E0;
  padding: 0 10px;
  margin-top: 40px;
  position: relative;
  top: 5px;
}

@media (max-width: 767px) { 
  .result-error {
    margin: 0;
  }
  
  .result-error .size h1 {
    font-size: 90px;
    font-weight: 700;
    color: #C0C0C0;
    margin: 0;
    max-height: 80px;
  }

  .result-error .size .size-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-height: 30px;
    margin-top: 0;
  }

  .result-error .size .size-name h2 {
    font-size: 14px;
    font-weight: 700;
  }

  .result-error .size .size-name svg {
    font-size: 20px;
  }

  .result-error .size .sizes-menu .menu-item {
    width: 110px;
    height: 29px;
    border: 1px solid #F0F0F0;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .result-error .size .sizes-menu .menu-item p {
    font-size: 14px;
    font-weight: 400;
  }

  .result-error .size .error-mark-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 10px;
    left: 56px;
  }

  .result-error .size .error-mark {
    width: 79px;
    height: 19px;
    border: 1px solid #C41A1A;
    border-radius: 8px;
    background-color: #fff;
  }

  .result-error .size .error-mark p {
    font-size: 10px;
    font-weight: 400;
    color: #C41A1A;
    margin-top: 3px;
  }

  .result-error .size .sizes-menu .active p {
    color: #F0F0F0;
    font-size: 14px;
    font-weight: 600;
  }

  .result-error-body .text .result-description {
    max-width: 165px;
  }

  .result-error-body {
    height: 184px;
  }

  .result-error-body .result img {
    width: 77px;
    height: 158px;
  }

  .result-error-body .result-title {
    font-size: 12px;
    font-weight: 400;
    color: #002D18;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
  }

  .result-error-body .gallery {
    position: relative;
    width: 100%;
    overflow: visible;
    display: flex;
    justify-content: center;
    min-height: 100%;
  }

  .result-error .size .sizes-menu .active {
    background-color: #002D18;
    border: 1px solid #002D18;
  }

  .result-error-body .gallery .gallery__item .gallery__info p {
    text-align: left;
  }

  .skip-error {
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    min-height: 40px;
  }
}