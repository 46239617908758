.gender-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.gender-buttons button {
  padding: 10px 20px;
  border: 1px solid #F0F0F0;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: 129px;
  height: 48px;
}

.gender-buttons button.selected {
  color: #fff;
}

.gender-buttons:focus {
  outline: none;
}

@media (max-width: 767px) {
  .gender-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  
  .gender-buttons button {
    padding: 10px 20px;
    border: 1px solid #F0F0F0;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 100%;
    height: 48px;
  }
  
  .gender-buttons button.selected {
    color: #fff;
  }
}