.tag-container .tag-style {
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 767px) { 
  .tag-container .tag-style {
    font-size: 10px;
    font-weight: 600;
  }
}