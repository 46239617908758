.main-qr-container {
  min-height: 72.3vh;
}

.main-qr-container .text-block {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 20px;
}

.main-qr-container .text-block .title-scan {
  display: flex;
}

.main-qr-container .text-block-mobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}