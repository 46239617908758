.breasts {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
}

.breasts__select {
  background: transparent;
  cursor: pointer !important;
  width: 75px;
  height: 35px;
}

.breasts__select .MuiSelect-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.breasts__select .MuiSelect-icon {
  position: relative;
  bottom: 0;
  color: #000;
  cursor: pointer;
}

.breasts__select .MuiOutlinedInput-notchedOutline {
  border-style: none;
}

.breasts__select .MuiOutlinedInput-input {
  padding-right: 20px !important;
  padding-left: 0 !important;
}

.breasts__select .MuiInputBase-input .MuiOutlinedInput-input {
  padding: 0 !important;
}

.breasts__select__item {
  display: flex;
  flex-direction: row;
}

.breasts__select__item.Mui-selected {
  background-color: transparent !important;
}

.breasts__select__item.Mui-selected:hover {
  background-color: transparent !important;
}

.breasts__select .breasts__select__item span {
  font-size: 13px;
  color: #000000;
}

.breasts__select__item__flag-icons {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 15px;
  font-size: 13px;
  position: relative;
  left: 8px;
  border-radius: 2px;
}

.breasts__select__item__flag-icons::before {
  content: ' ';
}

.flag-icon {
  border-radius: 2px;
}

.flag-icon.FR {
  background-image: url(../../assets/breasts/FR.svg);
}

.flag-icon.UK {
  background-image: url(../../assets/breasts/UK.svg);
}

.flag-icon.IT {
  background-image: url(../../assets/breasts/IT.svg);
}

.flag-icon.US {
  background-image: url(../../assets/breasts/US.svg);
}

.flag-icon.JP {
  background-image: url(../../assets/breasts/JP.svg);
}

.flag-icon.KO {
  background-image: url(../../assets/breasts/KO.svg);
}

.flag-icon.AUS {
  background-image: url(../../assets/breasts/AUS.svg);
}

.flag-icon.EU {
  background-image: url(../../assets/breasts/EU.svg);
}

.breasts__titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breasts__titles__title {
  width: 100%;
}

.breasts__body {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.breasts__body__title {
  font-weight: 700;
  width: 100%;
  text-align: start;
}

.breasts__body__table {
  display: flex;
  align-items: center;
  border-collapse: separate;
  flex-wrap: wrap;
  max-width: 100%;
  /* min-height: 120px; */
}

.breasts__body__table tr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  right: 5px;
}

.breasts__body__table td {
  display: inline-block;
  text-align: center;
  margin: 5px;
}

.breasts__body__table__cell {
  border: none;
  color: black;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.breasts__body__table__cell:hover {
  border: 1px solid black;
}

.breasts__body__table__cell--selected {
  color: white;
  background: black;
  border: 1px solid black;
}

.step-bottom {
  margin-top: 30px;
}

.breasts-skip {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
  min-height: 40px;
}

@media only screen and (min-width: 1000px) {
  .breasts {
    justify-content: center;
  }

  .breasts-description {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .selector_block {
    display: flex;
    gap: 10px;
    margin-left: 15px;
    align-items: center;
  }

  .selector_block p {
    font-size: 13px;
    font-weight: 600;
  }

  .breasts__select__item {
    padding: 0 !important;
    min-height: 35px !important;
    position: relative;
    left: 0;
  }

  .breasts__select__item__text {
    font-size: 14px;
  }

  .breasts__title {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .breasts__body {
    flex-direction: column;
  }

  .breasts__body__table {
    border-spacing: 10px 10px;
  }

  .breasts__body__table__cell {
    width: auto;
    height: auto;
    cursor: pointer;
  }

  .breasts__buttons {
    width: 400px;
    gap: 10px;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .breasts {
    justify-content: start;
  }

  .breasts-description {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .breasts p {
    text-align: left;
    margin-left: 0;
  }

  .breasts__select {
    width: 75px;
    height: 35px;
  }

  .breasts__select .MuiSelect-icon {
    position: relative;
    right: 8px;
    top: 0px;
    color: #000;
  }

  .breasts__select__item {
    padding: 0 !important;
    min-height: 30px !important;
  }

  .breasts__select__item__text {
    font-size: 14px;
  }

  .breasts__select__item__flag-icons {
    width: 17px;
    height: 12px;
    top: -1px;
    border-radius: 1px;
  }

  .breasts__title {
    font-size: 10px;
    margin-top: 14px;
    margin-bottom: 30px;
  }

  .breasts__body {
    flex-direction: column;
    margin-bottom: 0px;
    gap: 0px;
  }

  .breasts-continue {
    margin-top: 30px;
  }

  .breasts-continue:disabled {
    margin-top: 30px;
  }

  .breasts__body__title {
    font-size: 15px;
    margin-bottom: 4px;
  }

  .breasts__body__table {
    border-spacing: 5px 5px;
    min-height: 20px;
  }

  .breasts__body__table__cell {
    width: auto;
    height: auto;
    cursor: pointer;
  }

  .breasts__buttons {
    width: 100%;
    gap: 5px;
    margin-top: 0px;
  }

  .selector_block {
    display: flex;
    gap: 10px;
    margin-left: 5px;
    align-items: center;
  }

  .selector_block p {
    margin-left: 0;
  }

  .breasts-skip {
    margin-top: 0;
    margin-bottom: 0px;
    text-align: center;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    text-decoration: underline;
    min-height: 40px;
  }
}