@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins, Archivo', sans-serif;
  overflow-anchor: none;
}

b {
  font-weight: 600;
}

.popin-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 250;
  width: 100%;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  transition: opacity .4s ease;
}

@media only screen and (max-width: 1190px) {
  .popin-wrapper {
    bottom: 0;
    height: 100%;
  }
}