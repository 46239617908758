.mobile-qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.mobile-qr-container .qrcode-video {
  min-height: 210px;
  height: 210px;
  min-width: 210px;
}

.mobile-qr-container .help-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mobile-qr-container .help-info span {
  font-size: 11px;
  font-weight: 600;
  color: #002D18;
  text-decoration: underline;
  cursor: pointer;
}

/* popup  */
#info-popper {
  position: absolute;
  z-index: 9999;
}

.popper-container {
  position: relative;
  bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 !important;
  box-shadow: none !important;
  z-index: 4 !important;
  max-width: 250px;
  width: auto;
  height: auto;
  text-align: center;
  align-items: center;
}

.popper-container p {
  font-size: 12px !important;
  font-weight: 500;
  text-align: center;
  max-height: 120px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.popper-triangle {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid #FFFFFF;
  border-right: 7px solid #FFFFFF;
  z-index: 10 !important;
}

.popper-triangle::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -6px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid #FFFFFF;
  z-index: 10 !important;
}