.measured-by {
  font-size: 12px;
  color: #000000B2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  min-height: 40px;
  width: 100%;
  font-weight: 300;
}

.measured-by span {
  font-size: 12px;
  font-weight: 800;
  color: #000000;
  text-align: center;
}

.policy {
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 3px;
}

@media (max-width: 767px) {
  .measured-by {
    font-size: 12px;
    color: #000000B2;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 5px;
    position: relative;
    left: 50%;
    top: 10px;
    bottom: 0;
    transform: translateX(-50%);
    min-height: 20px;
    font-weight: 300;
  }

  .measured-by span {
    font-size: 12px;
    font-weight: 800;
    color: #000000;
    text-align: center;
  }

  .policy {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 3px;
  }
}