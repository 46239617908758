.stepper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.stepper .step {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #D9D9D9;
}

.stepper .step_active {
  background-color: black;
}