.result {
  margin-top: 40px;
}

.result .size {
  text-align: center;
}

.result .unavailable-button {
  width: 84px;
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  right: 100px;
  top: 160px;
}

.result .size h1 {
  font-size: 72px;
  margin: 0;
  margin-top: 40px;
}

.result .size .size-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 20px;
}

.result .size .size-name h2 {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.result .size .size-name svg {
  font-size: 16px;
  position: relative;
}

.result .size .sizes-menu {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.result .size .sizes-menu .menu-item {
  width: calc(100% / 3);
  height: 40px;
  border: 1px solid #F0F0F0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.result .size .sizes-menu .active {
  width: calc(100% / 3);
  height: 40px;
  box-sizing: border-box;
}

.result-body {
  margin-top: 20px;
  width: 100%;
  height: 242px;
  border: 1px solid #E9E9E9;
  display: flex;
  flex-direction: row;
}

.result-body .result {
  width: 100%;
}

.result-body .text {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.result-body-text {
  display: flex;
  margin: 25px 0;
  min-height: 40px;
}

.result-body-text p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin: 0;
}

.result-body .text .result-title {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  position: relative;
  right: 12px;
  text-align: center;
  justify-content: center;
}

.result-body .text .result-title svg {
  height: 12px;
}

.result-body .text .result-title p {
  font-size: 13px;
  font-weight: 600;
  color: #002D18;
  margin: 0;
  padding: 0;
}

.result-body .text .result-description {
  font-size: 13px;
  font-weight: 400;
  color: #002D18;
  margin: 0;
  padding: 0;
  max-width: 200px;
  margin-top: 4px;
  padding: 0 20px;
  min-height: 64px;
}

.result-body .result {
  text-align: center;
  align-items: center;
  display: flex;
  margin: 0;
}

.result-body .result img {
  width: 181px;
  height: 202px;
}

.result-body .result .arrows {
  display: flex;
  flex-direction: column;
}

.result-body .result .arrows .arrow-box-top {
  display: inline-block;
  background-color: #FFEEE2;
  color: #000;
  padding: 5px 20px;
  border-radius: 12px;
  position: relative;
  font-size: 14px;
  bottom: 40px;
  max-width: 64px;
}

.result-body .result .arrows .arrow-box-top::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 56%;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent #FFEEE2 transparent transparent;
}

.result-body .result .arrows .arrow-box {
  display: inline-block;
  background-color: #d3e8d3;
  color: #000;
  padding: 5px 20px;
  border-radius: 12px;
  position: relative;
  font-size: 14px;
  top: 20px;
}

.result-body .result .arrows .arrow-box::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 56%;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent #d3e8d3 transparent transparent;
}

.step-bottom-result:disabled {
  cursor: default;
}

.skip {
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
  color: #2E2E2E;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

@media (max-width: 767px) { 
  .result {
    margin: 0;
  }
  
  .result .size h1 {
    font-size: 60px;
    margin: 0;
  }

  .result .size .size-name svg {
    font-size: 20px;
    position: relative;
  }

  .result .size .sizes-menu {
    display: flex;
    width: 100%;
  }

  .result-body .result {
    display: flex;
    justify-content: center;
  }

  .result-body-text {
    display: flex;
    margin: 15px 0;
    min-height: 60px;
  }

  .result-body .result .arrows .arrow-box-top {
    position: relative;
    font-size: 8px;
    font-weight: 300;
    bottom: 20px;
    max-width: 64px;
  }
  
  .result-body .result .arrows .arrow-box {
    position: relative;
    font-size: 8px;
    font-weight: 300;
    top: 10px;
  }

  .skip {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) { 
  .result-body {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    width: auto;
    padding: 0;
    gap: 0;
    border: none;
  }
 
  .result-body .result {
    display: flex;
    justify-content: center;
  }

  .result-body .text .result-title p {
    font-size: 10px;
    width: auto;
    max-width: 70px;
  }

  .result .size .sizes-menu .menu-item {
    width: calc(100% / 3);
    min-width: auto;
    height: 40px;
    border: 1px solid #F0F0F0;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
  
  .result .size .sizes-menu .active {
    width: calc(100% / 3);
    min-width: auto;
    height: 40px;
    border: 1px solid #002D18;
  }

  .result-body .text .result-title {
    display: flex;
    gap: 0;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    right: 0;
  }

  .result-body .text {
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-height: 30px;
    justify-content: center;
  }

  .result-body .text .result-description {
    max-width: 100%;
    margin-top: 0;
    padding: 0;
    font-size: 12px;
    text-align: center;
    min-height: 40px;
  }

  .step-bottom-result {
    padding: 0 15px;
  }
}