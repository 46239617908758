.drawer-overlay {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 1000;
  pointer-events: all;
}

.drawer-popup {
  width: 445px;
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(0);
  position: relative;
}

.drawer-popup-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-popup-header h2 {
  position: relative;
  top: 40px;
  width: 100%;
  text-align: center;
}

.drawer-popup-header .close-icon {
  cursor: pointer;
  padding: 3px;
  border-radius: 5px;
  position: fixed;
  right: 10px;
}

.drawer-popup-header .close-icon .icon {
  color: black;
  width: 11px;
  height: 11px;
}

@media (max-width: 767px) {
  .drawer-overlay {
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0;
  }

  .drawer-popup {
    width: calc(100% - 50px);
    position: fixed;
    bottom: 0;
    height: auto;
    padding: 20px 25px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .drawer-popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .drawer-popup-header .close-icon {
    cursor: pointer;
    padding: 3px;
    border-radius: 5px;
    position: fixed;
    right: 10px;
  }
}