.qrcode {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}

.qrcode_animation {
  height: 220px;
}