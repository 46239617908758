.brands-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.brands-bar .brands-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brands-bar .brands-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brands-bar .brands-block .brands {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #000000 transparent;
  padding-right: 10px;
}

.brands-bar .brands-block .brands .item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  border: 1px solid #919191;
  border-radius: 10px;
}

.brands-bar .brands-block .brands .item p {
  margin: 0;
  font-size: 14px;
  color: #000000;
}

.search-bar .search-bar-input {
  font-size: 12px;
  color: #919191;
  padding-left: 10px;
  border: 1px solid #919191;
  border-radius: 10px;
}