/* Futura */
@font-face {
  font-family: "Futura";
  src: local("Futura"), url(./Futura/Futura-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Futura";
  src: local("Futura Extra-Bold"), url(./Futura/FuturaExtraBold.otf) format("truetype");
  font-weight: 800; /* Extra-Bold */
  font-style: normal;
}

/* Graphik */
@font-face {
  font-family: "Graphik";
  src: local("Graphik Regular"), url(./Graphik/GraphikRegular.otf) format("truetype");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik Medium"), url(./Graphik/GraphikMedium.otf) format("truetype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik Medium"), url(./Graphik/GraphikSemibold.otf) format("truetype");
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik Bold"), url(./Graphik/GraphikBold.otf) format("truetype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: local("Graphik ExtraBold"), url(./Graphik/GraphikExtraBold.otf) format("truetype");
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}