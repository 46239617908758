.belly_choice .belly-data-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
  align-items: center;
}

.belly_choice_selected .belly-data-container  {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
}

.belly_choice {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-transform: none;
  color: #000;
  width: calc(100% / 3);
  height: 220px;
  background-color: #fff;
  font-size: 13px;
  gap: 15px;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  padding: 0;
}

.belly_choice_selected {
  width: calc(100% / 3);
  height: 220px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-size: 13px;
  gap: 15px;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  padding: 0;
}

.belly_choice:hover {
  background-color: rgb(0, 0, 0, 0.02);
}

.belly_choice span {
  font-weight: 400;
  font-size: 13px;
  position: relative;
  top: 0;
}

.belly_choice_selected span {
  font-weight: 400;
  font-size: 13px;
  position: relative;
  top: 0;
}

.belly_choice__image {
  width: 90px;
  height: 120px;
  border-radius: 5px;
  position: relative;
  top: 0;
}

.belly_choice__image_loading {
  display: none;
}

.torso_choice__image {
  display: inline;
  width: 90px;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
  top: 0;
}

.torso_choice__image_loading {
  display: none;
}

.cuisses_choice__image {
  display: inline;
  width: 90px;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
  top: 0;
}

.cuisses_choice__image_loading {
  display: none;
}

@media (max-width: 767px) {
  .belly_choice {
    width: calc(100% / 3);
    height: 177px;
    font-size: 13px;
    justify-content: center;
    padding: 0;
  }

  .belly_choice_selected {
    width: calc(100% / 3);
    height: 177px;
    font-size: 13px;
    justify-content: center;
    padding: 0;
  }

  .belly_choice span {
    font-size: 13px;
    font-weight: 400;
    color: #2E2E2E;
    position: relative;
    top: 0;
  }

  .belly_choice_selected span {
    font-size: 13px;
    font-weight: 400;
    position: relative;
    top: 0;
  }

  .belly_choice__image {
    max-height: 100px;
    position: relative;
    top: 0;
  }

  .belly_choice  .belly_choice__image {
    max-height: 100px;
    position: relative;
    top: 0;
  }
  
  .belly_choice_selected .belly_choice__image {
    max-height: 100px;
    position: relative;
    top: 0;
  }

  .torso_choice__image {
    max-height: 100px;
    position: relative;
    top: 0;
  }

  .cuisses_choice__image {
    max-height: 100px;
    position: relative;
    top: 0;
  }
}