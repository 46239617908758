.belly-description {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 25px;
  text-align: center;
}

.belly {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  margin-top: 40px;
}

.belly .body {
  display: flex;
  gap: 10px;
  width: 100%;
}

.belly .controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.belly .controls .scan-text {
  position: relative;
  line-height: 1.2;
}

.belly .controls .scan-text.underline::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 1px;
  background-color: currentColor;
}

.belly .stepper {
  margin-top: 2.5%;
}

.belly .step-belly-button {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
}

.belly .step-belly-button:disabled {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  cursor: default;
}

.belly-bottom {
  margin-top: 130px;
}

.scan-CTA-button {
  margin-bottom: 60px;
  height: 46px !important;
  padding: 0 !important;
}

@media (max-width: 767px) {
  .belly {
    display: flex;
    width: 100%;
    align-items: left;
    gap: 2px;
  }

  .belly .body {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .belly .controls {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .belly-description {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .belly-description p {
    margin: 0;
    margin-bottom: 20px;
  }

  .belly .step-belly-button {
    margin-top: 10px;
  }
  
  .belly .step-belly-button:disabled {
    margin-top: 10px;
    cursor: default;
  }

  .belly-bottom {
    margin-top: 0;
  }
}

@media (max-height: 875px) and (min-width: 1000px) {
  /* .belly-description {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  } */

  .belly .step-belly-button {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0;
  }
  
  .belly .step-belly-button:disabled {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0;
    cursor: default;
  }
}

@media (max-height: 765px) and (min-width: 767px) {
  .belly {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
    width: 100%;
    margin-top: 0px !important;
  }

  .belly .controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .belly-bottom {
    margin-top: 30px;
  }
  
  .scan-CTA-button {
    margin-bottom: 0px;
  }

  .belly .step-belly-button {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px !important;
  }
  
  .belly .step-belly-button:disabled {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px !important;
    cursor: default;
  }
}