form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-container {
  position: relative;
}

.input-container .unit-options {
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: #666;
  z-index: 1;
}

.baseErrorTitle {
  color: #DA3939;
  position: relative;
  top: 0;
  font-size: 11px;
}

.ageErrorTitle {
  color: #DA3939;
  position: relative;
  bottom: 7px;
  font-size: 11px;
}

input {
  padding: 12px;
  padding-left: 0;
  border: none;
  border-bottom: 1px solid #002D18;
  font-weight: 400;
  outline: none;
  width: calc(100% - 20px);
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
}

input:-webkit-autofill {
  background-color: #FFF !important;
  box-shadow: 0 0 0px 1000px #FFF inset !important;
}

@media (max-width: 767px) {
  .drawer-popup-content input {
    border: 1px solid #002D18;
    font-weight: 400;
  }

  input {
    font-size: 16px !important;
    transform: scale(1);
  }

  .drawer-popup-content form {
    gap: 8px;
  }
  .baseErrorTitle {
    color: red;
    position: relative;
    font-size: 12px;
  }
  
  .ageErrorTitle {
    color: red;
    position: relative;
    bottom: 0;
    font-size: 12px;
  }
}