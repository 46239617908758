.baseErrorTitle {
  color: red;
  position: relative;
  top: 5px;
}

input:-webkit-autofill,
input:-internal-autofill-selected {
  background-color: #FFF !important;
  color: #000 !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
}