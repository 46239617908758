.consent {
  outline: none;
}

.consent .consent-description {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 400px;
}

.consent .consent-description p {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  color: #2E2E2E;
}

.consent .check-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  max-width: 400px;
}

.consent .check-container p {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  color: #2E2E2E;
}

@media (max-width: 767px) {
  .consent .check-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 0;
  }
}