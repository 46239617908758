.description {
  font-size: 16px;
  margin-top: 35px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.descriptionSmall {
  font-size: 14px;
  margin-bottom: 50px;
  display: flex;
  justify-content: left;
}

.continue-button {
  padding: 16px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  width: 100%;
}

.continue-button:disabled {
  padding: 16px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #6F857B;
  width: 100%;
  cursor: default;
}

.gender-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  bottom: 5px;
}

.gender-block label {
  font-size: 20px;
  font-weight: 600;
}

.drawer-popup-content form label {
  color: #002D18;
}

.main-block {
  margin-top: 50px;
}

@media (max-width: 767px) {
  h2 {
    font-size: 21px;
    margin-top: 25px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .descriptionSmall {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .continue-button {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
    height: 48px;
    text-align: center;
    align-items: center;
    padding: 0;
  }

  .continue-button:disabled {
    margin-top: 0px;
    padding: 0;
    width: 100%;
    height: 48px;
    cursor: default;
  }

  .drawer-popup-content form label {
    color: #002D18;
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}