/* Gallery */
.gallery-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gallery-container .title {
  margin: 20px 0 5px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.gallery-container .scroll-container {
  display: flex;
  gap: 10px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.gallery-container .scroll-container::-webkit-scrollbar {
  display: none;
}

.gallery-container .scroll-container .info {
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.gallery-container .scroll-container p {
  font-size: 12px;
  font-weight: 400;
  max-width: 160px;
  margin: 0;
}

.gallery-container .scroll-container .info-text {
  text-align: left;
  max-height: 80px;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.gallery-container .left-arrow {
  position: absolute;
  left: 32px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFF;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;
}

.gallery-container .right-arrow {
  position: absolute;
  right: 32px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FFF;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;
}

.gallery-container .scroll-container .img-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-container .left-arrow-srp {
  position: absolute;
  left: 32px;
  width: 31px;
  height: 80px;
  border-radius: 4px;
  background-color: #FFF;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(240, 240, 240, 0.3);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(2.5px);  
}

.gallery-container .right-arrow-srp {
  position: absolute;
  right: 32px;
  width: 31px;
  height: 80px;
  border-radius: 4px;
  background-color: #FFF;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(240, 240, 240, 0.3);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(2.5px);  
}

.dots-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(39, 38, 38, 0.08);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-shrink: 0;
}

.dot.active {
  background-color: rgba(39, 38, 38, 1);
}

@media (max-width: 767px) { 
  .dots-container {
    margin-top: 0;
  }

  .gallery-container .scroll-container .info {
    gap: 10px;
  }
}