.main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
}

.button-container {
  background-color: #FFF;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  padding-right: 35px;
  text-align: center;
  cursor: pointer;
}

.button-container:hover {
  background-color: #EFEFEF;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  padding-right: 35px;
  text-align: center;
  cursor: pointer;
}

.button-container:active {
  background-color: #E0E0E0;
  border: 1px solid #F0F0F0;
  border-radius: 5px;
  display: flex;
  padding: 20px;
  padding-right: 35px;
  text-align: center;
  cursor: pointer;
}

.button-container .parts-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-container .title-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.button-container .title-container .icon-text {
  display: flex;
  gap: 10px;
}

.button-container .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
}

.button-container .title2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: left;
  text-align: left;
  max-width: 315px;
}

@media (max-width: 767px) {
  .main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    min-height: 32vh;
    height: 100%;
  }

  .button-container {
    display: flex;
    padding: 10px 15px;
    padding-right: 25px;
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }

  .button-container:hover {
    background-color: #EFEFEF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    display: flex;
    padding: 10px 15px;
    padding-right: 25px;
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }
  
  .button-container:active {
    background-color: #E0E0E0;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    display: flex;
    padding: 10px 15px;
    padding-right: 25px;
    padding-bottom: 5px;
    text-align: center;
    cursor: pointer;
  }

  .button-container .title-container {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .button-container .parts-container {
    display: flex;
    gap: 15px;
  }
  .button-container .title2 {
    display: flex;
    justify-content: left;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-height: 765px) and (min-width: 767px) {
  .main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px !important;
  }
}