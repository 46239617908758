.breasts .brand-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 5px 20px;
  border: 1px solid #919191;
  border-radius: 10px;
}

.breasts .brand-item .brand-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.breasts .brand-item .brand-info  p {
  margin: 0;
  font-size: 14px;
  color: #000000;
}